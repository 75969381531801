import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./style.css"
import Header from "../components/Header/index"
//import Content from "../components/Content"
import Footer from "../components/Footer"
import Sobre from "../components/Content-Sobre"

export default function Home() {
  return (
    <div>
      <Header />
      <div className="container">
        <Sobre />
      </div>
      <Footer />
    </div>
  )
}
